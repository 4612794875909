import { Box, Button, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import React from "react";
import Logo from "../../components/Logo";
import { useHistory } from "react-router-dom";
import muiTheme from "../../styles/theme";

const Wrapper = styled(Grid)`
  width: 100%;
  height: 100vh;
  position: relative;
`;

const StyledImage = styled(Box)`
  background-image: url("https://res.cloudinary.com/hc6vep858/image/upload/v1582047772/titulka/club/CLUB-feb2020-1_wadoi8.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(60%);
  ${muiTheme.breakpoints.down("md")} {
    background-position-x: 30%;
  }
`;

const StyledButton = styled(Button)`
  && {
    border-radius: 3rem;
  }
`;

const Index = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/about");
  };

  return (
    <Wrapper container direction="column">
      <Box
        py={{ xs: "1rem" }}
        bgcolor="white"
        display="flex"
        justifyContent="center"
      >
        <Logo />
      </Box>
      <Grid
        container
        style={{ flex: "1" }}
        justify="center"
        alignItems="center"
      >
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <Box fontWeight="normal" mb="1rem">
              <Typography variant="h2" color="secondary" gutterBottom>
                Spring 2021
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleClick}
            >
              <Typography variant="button">About</Typography>
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
      <StyledImage
        zIndex="-1"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
      />
    </Wrapper>
  );
};

export default Index;
