import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const Logo = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/");
  };
  return (
    <Box fontWeight="bold" onClick={handleClick}>
      <Typography variant="h1" display="inline" color="textPrimary">
        Ladies
        <Typography variant="h1" color="primary" display="inline">
          Hub
        </Typography>
      </Typography>
    </Box>
  );
};

export default Logo;
