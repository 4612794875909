import { createMuiTheme } from "@material-ui/core/styles";

const muiTheme = createMuiTheme({
  direction: "ltr",
  palette: {
    primary: {
      main: "#995DBB",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#995DBB",
    },
    text: {
      primary: "#000000",
      secondary: "#7C7B81",
    },
    divider: "#DDDDDD",
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  spacing: 16,
});

muiTheme.typography.h1 = {
  fontSize: "2.25rem",
  fontWeight: "inherit",
  lineHeight: "1",
  [muiTheme.breakpoints.up("sm")]: {
    fontSize: "3.75rem",
  },
  [muiTheme.breakpoints.up("xl")]: {
    fontSize: "5rem",
  },
};
muiTheme.typography.h2 = {
  fontSize: "1.4rem",
  fontWeight: "bold",
  [muiTheme.breakpoints.up("sm")]: {
    fontSize: "2.2rem",
  },
  [muiTheme.breakpoints.up("lg")]: {
    fontSize: "3rem",
  },
};
muiTheme.typography.h3 = {
  fontSize: "1.2rem",
  fontWeight: "bold",
  lineHeight: 1.3,
  [muiTheme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },

  [muiTheme.breakpoints.up("xl")]: {
    fontSize: "2rem",
    lineHeight: 1.5,
  },
};

muiTheme.typography.body1 = {
  fontSize: "1rem",
  fontWeight: "normal",
  lineHeight: 1.5,
  [muiTheme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
    lineHeight: 1.7,
  },
};

muiTheme.typography.subtitle1 = {
  fontSize: "1rem",
  fontWeight: "normal",
};

muiTheme.typography.button = {
  fontSize: "1.2rem",
  fontWeight: "bold",
  [muiTheme.breakpoints.up("lg")]: {
    fontSize: "1.7rem",
  },
};

export default muiTheme;
