import React from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import Logo from "../../components/Logo";
import FavoriteIcon from "@material-ui/icons/Favorite";
import styled from "styled-components";

const teamData = [
  {
    nameAge: "Dan (36)",
    role: "Co-Founder",
    image:
      "https://res.cloudinary.com/hc6vep858/image/upload/v1600357580/LadiesHub_temp_team/Screenshot_2020-09-16_at_13.18.08_l9ogfw.png",
  },
  {
    nameAge: "Mike (39)",
    role: "Director",
    image:
      "https://res.cloudinary.com/hc6vep858/image/upload/v1600182339/LadiesHub_temp_team/mike_bvpcny.png",
  },
  {
    nameAge: "Saimon (27)",
    role: "UX/UI",
    image:
      "https://res.cloudinary.com/hc6vep858/image/upload/v1600182339/LadiesHub_temp_team/Simon_pg8sjc.png",
  },
  {
    nameAge: "Vaness (22)",
    role: "Translator",
    image:
      "https://res.cloudinary.com/hc6vep858/image/upload/v1600182339/LadiesHub_temp_team/Vaness_nkvlu0.png",
  },
];

const Photo = styled(Box)`
  ${({ src }: { src: string }) => `background-image: url(${src});`}

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const About = () => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Box
          py={{ xs: "1rem" }}
          bgcolor="white"
          display="flex"
          justifyContent="center"
        >
          <Logo />
        </Box>
      </Grid>
      <Container>
        <Grid container direction="column" spacing={2}>
          <Grid item style={{ textAlign: "justify" }}>
            <Typography variant="body1" display="inline">
              Is a software project, advertising site, similar to “Craigslist”
              that brings together women and men. Our portal provides quality
              and modern advertising space with many improvements and
              functionalities. We currently operate in Czech Republic, Hungary
              and Slovakia.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2" gutterBottom>
              Our Team <FavoriteIcon color="primary" fontSize="inherit" />
            </Typography>
            <Grid item container spacing={1}>
              {teamData.map((e) => (
                <Grid
                  key={e.nameAge}
                  item
                  xs={6}
                  md={4}
                  lg={2}
                  direction="column"
                  alignItems="center"
                >
                  <Photo
                    width="100%"
                    height={{
                      xs: "150px",
                      sm: "300px",
                      lg: "250px",
                      xl: "320px",
                    }}
                    mb={{ xs: ".5rem" }}
                    src={e.image}
                  />
                  <Typography variant="h3">{e.nameAge}</Typography>
                  <Typography variant="body1" color="textSecondary">
                    {e.role}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: "justify" }}>
            <Typography variant="h2" gutterBottom>
              Business
            </Typography>
            <Typography variant="body1">
              The portal has also its commercial level.Its revenue is generated
              by
            </Typography>
            <ul>
              <Typography variant="body1" component="li">
                Client(men) memberships
              </Typography>
              <Typography variant="body1" component="li">
                High-listing(top -listing) of advertisers’ catalog entries
              </Typography>
              <Typography variant="body1" component="li">
                Highlighting advertisers’ catalog entries
              </Typography>
            </ul>
            <Typography variant="body1">
              <b>
                There is NO fee for sex services through Ladieshub, nor is ANY
                commission charged.
              </b>{" "}
              We offer a unique, professional and innovative platformthat is
              stable and built on <b>cutting-edge technologies</b>.
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "justify" }}>
            <Typography variant="h2" gutterBottom>
              Legal
            </Typography>
            <Typography variant="body1">
              The portal is operated by the non-profit association OZ 72 54 36,
              which is legally registered at Ministry of Interior of the Slovak
              Republic with the right to operate within the European Union. We
              work legally.{" "}
              <b>
                Again, we do not offer any kind of sexual work, nor do we take
                any commission from it. The portal is not accessible to minors
                and is R-rated. This means that no minor may advertise or become
                a member.
              </b>{" "}
              We check profiles regularly. Every advertisement is strictly
              controlled to prevent the spread of inappropriate behavior, racism
              and other illegal content. Legal documents and processes of GDPR
              are covered by a renowned professional law firm.
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "justify" }}>
            <Typography variant="h2" gutterBottom>
              Refund policy
            </Typography>
            <Typography variant="body1">
              We have a very transparent refund policy written directly on every
              page in footer section. Also we will setup Trustpilot and it will
              be visible also in footer section.{" "}
              <b>Custormer experiance and safety is our main objective!</b>
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "justify" }}>
            <Typography variant="h2" gutterBottom>
              Domains
            </Typography>
            <Typography variant="body1">
              We have 14 registered domains: .COM, SK, EU, CZ, HU, AT, PL,
              CLUB,STORE, VIP, ROCKS, ORG, INFO, ONLINE
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "justify" }}>
            <Typography variant="h2" gutterBottom>
              Tech Stack
            </Typography>
            <Typography variant="body1">
              Our basic Tech Stack is: Node.js + GraphQL, React, Heroku for
              deployments, Phrase and Cloudinary for storing documents. We are
              also fully integrated on Google cloud platform.
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "justify" }}>
            <Typography variant="h2" gutterBottom>
              Timing and place
            </Typography>
            <Typography variant="body1">
              Estimated go live of the project in Slovakia, Czech Republic and
              Hungary is Q1 2021. Might you be interested in any missing detail
              of the puzzle, do not hesitate to contact us.
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "justify" }}>
            <Typography variant="h2" gutterBottom>
              Contact
            </Typography>
            <Typography variant="body1" gutterBottom>
              <a href="mailto:mike@ladieshub.com">mike@ladieshub.com</a>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <a href="tel:+421903176687">+421 903 176 687</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default About;
