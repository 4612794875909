import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import About from "./scenes/About/";
import Index from "./scenes/Index";
import muiTheme from "./styles/theme";

function App() {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={muiTheme}>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/about" exact component={About} />
        </Switch>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
